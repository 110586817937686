<template>
  <div class="tab-box">
    <div @click="Filter = false">
      <div class="tab-header">
        <el-radio-group v-model="radio1" @input="monitor">
          <el-radio-button label="1"><span>我发起的</span></el-radio-button>

          <el-radio-button label="2"><span>待办任务</span>
            <div class="num" v-if="total > 0">{{ total }}</div>
          </el-radio-button>

          <el-radio-button label="3"><span>已办任务</span></el-radio-button>

          <el-radio-button label="4"><span>我收到的</span>
            <div class="num" v-if="CountMsg > 0">{{ CountMsg }}</div>
          </el-radio-button>

          <!-- <el-radio-button label="5"><span>转交我的</span></el-radio-button> -->
        </el-radio-group>
      </div>

      <div class="search-header">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <el-input placeholder="流程名称/摘要" v-model="formInline.name" class="search-input">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-form-item>

          <el-form-item v-if="radio1 != 1">
            <el-select v-model="formInline.sponsor" clearable filterable placeholder="发起人" class="search-input"
              :popper-append-to-body="false" @change="searchInput">
              <template #prefix>
                <i class="el-icon-search custom-icon" style="margin-top: 2px;"></i>
              </template>

              <el-option v-for="item in UserList" :key="item.userId" :label="item.userName" :value="item.userId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="processStatus" v-if="radio1 != 2" clearable placeholder="请选择流程状态"
              class="select-ProcessStatus" style="width: 140px; padding: 0px !important;" @change="handleCommand">
              <el-option label="进行中" value="1"> </el-option>
              <el-option label="已完成" value="2"> </el-option>
              <el-option label="已取消" value="3"> </el-option>
            </el-select>

            <div ref="here"></div>
          </el-form-item>

          <el-form-item>
            <el-button class="el-button-box" icon="el-icon-search" @click.stop="openFilter">高级筛选</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="content-list">
        <!-- 我的发起 -->

        <Initiated v-if="radio1 == 1" :showNewly="type1" @showlist="reset" @openDrawer="openDrawer" ref="Initiated">
        </Initiated>

        <Backlog v-if="radio1 == 2" :showNewly="type2" @showlist="reset" @openDrawer="openDrawer" ref="Backlog">
        </Backlog>

        <HaveFinished v-if="radio1 == 3" :showNewly="type3" @showlist="reset" @openDrawer="openDrawer"
          ref="HaveFinished">
        </HaveFinished>

        <Received v-if="radio1 == 4" :showNewly="type4" @showlist="reset" @openDrawer="openDrawer" ref="Received">
        </Received>

        <Referred v-if="radio1 == 5" :showNewly="type5" @showlist="reset" @openDrawer="openDrawer" ref="Referred">
        </Referred>
      </div>
    </div>

    <div class="sx-box" v-if="Filter">
      <div class="sx-name">
        所属分组
      </div>

      <el-cascader style="width: 336px; height: 36px; border-radius: 4px; ;margin-bottom: 16px;"
        v-model="formInline.formIds" :options="categoryList" :show-all-levels="false"
        :props="{ label: 'name', value: 'id', multiple: true, emitPath: false }" :collapse-tags="true">
      </el-cascader>

      <div v-if="radio1 == 1" class="sx-name">
        提交时间
      </div>

      <div v-if="radio1 == 2" class="sx-name">
        发起时间
      </div>

      <div v-if="radio1 == 3 || radio1 == 4 || radio1 == 5" class="sx-name">
        创建时间
      </div>

      <el-date-picker v-model="formInline.createTime[0]"
        style="width: 336px;height: 36px; border-radius: 4px !important ;margin-bottom: 16px;"
        value-format="yyyy-MM-dd HH:mm:ss" type="date" range-separator="-" placeholder="选择日期" :append-to-body="false">
      </el-date-picker>

      <div class="sx-name">
        结束时间
      </div>

      <el-date-picker v-model="formInline.createTime[1]"
        style="width: 336px;height: 36px; border-radius: 4px !important;margin-bottom: 16px;"
        value-format="yyyy-MM-dd HH:mm:ss" type="date" range-separator="-" placeholder="选择日期"
        :append-to-body="false"></el-date-picker>

      <div class="sx-btn-box">
        <div class="sx-btn" @click="reset">
          重置
        </div>

        <div class="sx-btns" @click="search">
          搜索
        </div>
      </div>
    </div>

    <processDetails ref="processDetails" :drawerData="drawerData" @closeDrawer="closeDrawer"></processDetails>
  </div>
</template>

<script>
import processDetails from "@/components/processDetails.vue";
import { getTaskList, getCountMsg } from "@/api/examines.js";
import Initiated from "./conponents/initiated";

import Backlog from "./conponents/backlog";

import HaveFinished from "./conponents/haveFinished";

import Received from "./conponents/received";

import Referred from "./conponents/referred";

import { getFlowManagementList, getArrSimpleList } from "@/api/examines.js";

import Detail from "@/views/detail/index";

export default {
  name: "ApprovalCenter",
  components: {
    Initiated,

    Backlog,

    HaveFinished,

    Received,

    Referred,

    Detail,
    processDetails
  },

  data() {
    return {
      total: 0,
      CountMsg: 0,
      processStatus: "",
      state: 1,
      UserList: [],

      // 选中的数据

      drawerData: {},

      drawer: false,

      radio1: 1,

      formInline: {
        name: "",

        status: "",

        formIds: [],

        createTime: [],

        sotype: 0,

        sponsor: "",

        digest: ""
      },

      isDropdownVisible: true,

      categoryList: [],

      type1: {},

      type2: {},

      type3: {},

      type4: {},

      type5: {},

      Filter: false // 筛选弹窗
    };
  },
  async created() {
    console.log(`output->123`, 123)
    this.getFlowManagement();

    await this.handleSelect();
  },
  async mounted() {
    await this.setAgencyUnmeter();
    await this.setReceivedUnmeter();
  },
  // async activated() {
  //   await this.setAgencyUnmeter();
  //   await this.setReceivedUnmeter();
  // },

  watch: {
    // 监听formInline.name的变化
    "formInline.name"(newVal, oldVal) {
      // 值发生变化时触发搜索
      if (newVal !== oldVal) {
        this.searchInput();
      }
    },
    $route: {
      handler: function (val, oldVal) {
        if (val.path === "/main/workbench/Workflow/Navigate/approvalCenter") {
          if (this.$route.query.needDealt) {
            this.radio1 = this.$route.query.needDealt;
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    // 刷新

    closeDrawer() {
      // const key = {
      console.log(this.radio1);
      if (this.radio1 == 1) {
        this.$refs.Initiated.refresh(); // 调用子组件的方法
      } else if (this.radio1 == 2) {
        this.$refs.Backlog.refresh(); // 调用子组件的方法
      } else if (this.radio1 == 3) {
        this.$refs.HaveFinished.refresh(); // 调用子组件的方法
      } else if (this.radio1 == 4) {
        this.$refs.Received.refresh(); // 调用子组件的方法
      } else if (this.radio1 == 5) {
        this.$refs.Referred.refresh(); // 调用子组件的方法
      }
      this.setAgencyUnmeter();
      this.setReceivedUnmeter();
    },

    // 设置代办任务条数
    async setAgencyUnmeter(num) {
      const { data } = await getTaskList({ pageNo: 1, pageSize: 1 });
      this.total = data.total;
    },

    // 设置我收到的条数
    async setReceivedUnmeter(id) {
      if (id) {
        await getCountMsg(id);
      } else {
        const { data } = await getCountMsg();
        this.CountMsg = data;
      }
    },

    //获取人员名称

    async handleSelect() {
      const { rows } = await getArrSimpleList();

      this.UserList = rows;
    },

    // 流程状态

    handleCommand(command) {
      console.log(this.processStatus);
      this.formInline.status = this.processStatus;
      this.search();
    },

    async openDrawer(row) {
      this.drawerData = row;
      if (this.radio1 == 4) {
        await this.setReceivedUnmeter(row?.id);
        await this.setReceivedUnmeter();
        delete this.drawerData.taskId;
        this.$refs.Received.clearUnread(row?.id);
      }
      this.$refs.processDetails.drawer = true;
    },

    // 切换tab监听事件

    monitor(e) {
      this.formInline = {
        name: "",

        sponsor: "",

        formIds: [],

        createTime: [],

        status: ""
      };
      this.processStatus = "";
      console.log(e);
      this.radio1 = e;

      this.type1 = {};

      this.type2 = {};

      this.type3 = {};

      this.type4 = {};

      this.type5 = {};
    },

    // 获取分组信息

    getFlowManagement() {
      let isValid = true;

      getFlowManagementList(isValid).then(res => {
        console.log(res);

        this.categoryList = res.data;
      });
    },

    openFilter() {
      this.Filter = !this.Filter;
    },

    // 重置

    reset() {
      this.formInline = {
        name: "",

        sponsor: "",

        formIds: [],

        createTime: [],

        status: ""
      };

      this.type1 = {};

      this.type2 = {};

      this.type3 = {};

      this.type4 = {};

      this.type5 = {};

      this.Filter = false;
    },

    search() {
      console.log(this.formInline);

      if (this.radio1 == 1) {
        this.type1 = this.formInline;
      } else if (this.radio1 == 2) {
        this.type2 = this.formInline;
      } else if (this.radio1 == 3) {
        this.type3 = this.formInline;
      } else if (this.radio1 == 4) {
        this.type4 = this.formInline;
      } else if (this.radio1 == 5) {
        this.type5 = this.formInline;
      }

      this.Filter = false;
    },

    searchInput(e) {
      console.log(e);

      if (this.radio1 == 1) {
        this.type1 = this.formInline;
      } else if (this.radio1 == 2) {
        this.type2 = this.formInline;
      } else if (this.radio1 == 3) {
        this.type3 = this.formInline;
      } else if (this.radio1 == 4) {
        this.type4 = this.formInline;
      } else if (this.radio1 == 5) {
        this.type5 = this.formInline;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.num {
  width: 15px;
  height: 15px;
  background-color: rgb(255, 82, 25);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -28px;
  right: -50px;
  font-size: 10px;
  color: #ffffff;
}

::v-deep #el-drawer__title {
  margin-bottom: 8px;
}

::v-deep .el-drawer__body {
  flex: 1 1 0%;

  overflow: auto;

  padding-top: 0px;
}

.tab-box {
  width: 100%;

  padding: 12px 16px;

  box-sizing: border-box;

  position: relative;
}

.tab-header {
  text-align: left;
}

::v-deep .el-radio-button__inner {
  width: 76px;

  height: 28px;

  padding: 7px 11px;

  box-sizing: border-box;

  text-align: center;

  border-radius: 2px;

  background: rgba(243, 244, 246, 1) !important;

  border: 0px !important;

  margin-right: 12px;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border: 0px !important;

  border-radius: 2px !important;

  margin-right: 12px !important;
}

::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  border-radius: 2px !important;

  background: rgba(86, 105, 236, 0.15) !important;

  color: rgba(86, 105, 236, 1) !important;
}

::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  border: 0px !important;

  box-shadow: none !important;
}

::v-deep .el-radio-button__inner:hover {
  color: rgba(86, 105, 236, 1) !important;
}

.search-header {
  display: flex;

  justify-content: space-between;

  margin-top: 12px;
}

::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}

::v-deep .search-input {
  .el-input__inner {
    border: 0px !important;
    border-radius: 6px !important;

    background: rgba(243, 244, 246, 1) !important;

    color: #848484 !important;
  }
}

::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .el-input__prefix {
  height: 100% !important;
  top: -2px;
  left: -3px !important;
}

/* ::v-deep .el-input__prefix {
    top: 2px;
  } */

.content-list {
  width: 100%;
}

::v-deep .el-form--inline .el-form-item {
  margin-right: 8px !important;
}

.el-button-box {
  width: 100px;

  height: 34px;

  background-color: #f3f4f6 !important;

  color: #1b1b1b !important;

  border: 0px !important;

  padding: 10px 12px 10px 8px !important;

  box-sizing: border-box;
}

::v-deep .el-radio-button {
  width: 76px;

  height: 28px;

  border: 0px;

  margin-right: 12px;
}

/* ::v-deep .el-popper {

    width: 76px;

} */

::v-deep .el-dropdown-menu--small .el-dropdown-menu__item {
  padding: 0px !important;

  width: 76px;
}

::v-deep .el-dropdown-menu__item {
  display: block !important;

  text-align: center !important;
}

.el-dro-item {
  display: block !important;

  text-align: center !important;

  height: 32px !important;
}

.sx-box {
  width: 376px;

  border-radius: 4px;

  border: 1px solid rgba(243, 243, 243, 1);

  padding: 24px 20px;

  box-sizing: border-box;

  background: rgba(255, 255, 255, 1);

  position: absolute;

  right: 128px;
  top: 0px;

  text-align: left;
}

.sx-name {
  color: rgba(0, 0, 0, 1);

  font-family: "Noto Sans SC";

  font-weight: 400;

  font-size: 13px;

  line-height: 13px;

  margin-bottom: 7px;
}

.sx-btn-box {
  display: flex;

  justify-content: space-between;

  margin-top: 16px;
}

.sx-btn {
  width: 112px;

  height: 44px;

  border-radius: 2px;

  background: rgba(243, 244, 246, 1);

  line-height: 44px;

  text-align: center;

  color: var(--, rgba(132, 132, 132, 1));

  font-size: 13px;
}

.sx-btns {
  width: 204px;

  height: 44px;

  border-radius: 2px;

  background: rgba(86, 105, 236, 1);

  color: rgba(255, 255, 255, 1);

  font-size: 13px;

  line-height: 44px;

  text-align: center;
}

.drawer__title {
  text-align: left;

  color: rgba(27, 27, 27, 1);

  font-family: "Noto Sans SC";

  font-weight: 500;

  font-size: 14px;

  line-height: 14px;
}

/* 自定义图标和输入框的对齐 */

.custom-icon {
  font-size: 14px;

  display: flex;

  align-items: center;

  /* 垂直居中对齐 */

  height: 100%;

  /* 占满父元素高度 */

  padding-left: 10px;
}

/* 调整 el-select 的高度以确保图标和文字对齐 */

.el-select .el-input__inner {
  display: flex;

  align-items: center;
}

::v-deep .el-drawer__body {
  flex: 1 1 0%;

  overflow: hidden;

  padding: 0px;
}

::v-deep .select-ProcessStatus {
  .el-input__inner {
    border: 0px;
    background-color: rgb(243, 244, 246);
    color: rgb(132, 132, 132);
  }
}
</style>