var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "boxbox", attrs: { id: "app-1" } }, [
    _c(
      "body",
      [
        _c("keep-alive", [_c("router-view")], 1),
        _c("processDetails", {
          ref: "processDetails",
          attrs: { drawerData: _vm.drawerData }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }