<template>
    <div v-if="drawer">
        <el-drawer :visible.sync="drawer" direction="rtl" :modal="false" :custom-class="backgroundColor" :size="'40%'" >
            <!-- 使用具名插槽自定义标题 -->
            <template slot="title">
                <div class="drawer__title-box">
                    <img src="@/assets/ioc/angle-left.png" alt="" v-if="record.length > 1" @click="fallback" />
                    <span class="drawer__title">详情</span>
                </div>
            </template>
            <div>
                <Detail :drawerData="drawerData" @closeDrawer="closeDrawer" @setBackgColor="setBackgColor"
                    ref="DetailRef"></Detail>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import Detail from "@/views/detail/index";
    export default {
        components: {
            Detail
        },
        name: 'HFIMElectronWorkbenchProcessDetails',
        data() {
            return {
                drawer: false,
                state: 0,
                record: [],
            };
        },
        props: {
            drawerData: {
                type: Object,
                default: () => ({})
            }
        },
        watch: {
            drawer(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.record = [];
                }
            }
        },
        computed: {
            backgroundColor() {
                switch (this.state) {
                    case 1://进行中
                        return 'custom-drawer-haveInHand'
                        break;
                    case 2://已通过
                        return 'custom-drawer-adopt'
                        break;
                    case 3://未通过
                        return 'custom-drawer-notPassed'
                        break;
                    case 4://已撤销
                        return 'custom-drawer-revoke'
                        break;
                    default:
                        return 'white'
                        break;
                }
            }
        },

        mounted() {

        },

        methods: {
            closeDrawer() {
                this.drawer = false;
                this.$emit('closeDrawer');
            },
            setBackgColor(strg) {
                if (strg.status == 2) {
                    this.state = strg.result == 2 ? 2 : 3
                } else {
                    this.state = strg.status == 3 ? 4 : 1
                }
            },
            addRecord(data) {
                this.record.push(data);
            },
            fallback() {
                this.$refs.DetailRef.initial(this.record[this.record.length - 2])
                this.record.pop()
            },
        },
        provide() {
            return {
                addRecord: this.addRecord,
            };
        },
    };
</script>

<style scoped lang="scss">
    .drawer__title-box {
        display: flex;
        align-items: center;
        text-align: left;
        gap: 8px;
    }

    ::v-deep .custom-drawer-haveInHand {
        background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(86, 105, 236, 0.3) 100%), rgba(255, 255, 255, 1);
    }

    ::v-deep .custom-drawer-adopt {
        background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(93, 162, 172, 0.3) 100%), rgb(255, 255, 255);
    }

    ::v-deep .custom-drawer-notPassed {
        background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(250, 101, 89, 0.3) 100%), rgba(255, 255, 255, 1);
    }

    ::v-deep .custom-drawer-revoke {
        background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(188, 188, 188, 0.3) 100%), rgb(255, 255, 255);
    }

    ::v-deep .white {
        background: rgb(255, 255, 255)
    }

    ::v-deep #el-drawer__title {
        margin-bottom: 8px;
    }

    ::v-deep .el-drawer__body {
        flex: 1 1 0%;
        overflow: auto;
        padding-top: 0px;
    }

    .tab-box {
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        position: relative;
    }

    .tab-header {
        text-align: left;
    }

    ::v-deep .el-radio-button__inner {
        width: 76px;
        height: 28px;
        padding: 3px 12px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 2px;
        background: rgba(243, 244, 246, 1) !important;
        border: 0px !important;
        margin-right: 12px;
    }

    ::v-deep .el-radio-button:first-child .el-radio-button__inner {
        border: 0px !important;
        border-radius: 2px !important;
        margin-right: 12px !important;
    }

    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border-radius: 2px !important;
        background: rgba(86, 105, 236, 0.15) !important;
        color: rgba(86, 105, 236, 1) !important;
    }

    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border: 0px !important;
        box-shadow: none !important;
    }

    ::v-deep .el-radio-button__inner:hover {
        color: rgba(86, 105, 236, 1) !important;
    }

    .search-header {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
    }

    ::v-deep .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
        margin-bottom: 0px;
    }

    ::v-deep .search-input {
        .el-input__inner {
            border: 0px !important;
            border-radius: 6px !important;
            background: rgba(243, 244, 246, 1) !important;
            color: #848484 !important;
        }
    }

    /* ::v-deep .el-input__inner {
      border: 0px !important;
      border-radius: 6px !important;
      background: rgba(243, 244, 246, 1) !important;
      color: #848484 !important;
  } */

    ::v-deep .el-input__prefix {
        height: 100% !important;
        left: -3px !important;
    }

    .content-list {
        width: 100%;
        margin-top: 16px;
    }

    ::v-deep .el-form--inline .el-form-item {
        margin-right: 8px !important;
    }

    .el-button-box {
        width: 100px;
        height: 34px;
        background-color: #f3f4f6 !important;
        color: #1b1b1b !important;
        border: 0px !important;
        padding: 10px 12px 10px 8px !important;
        box-sizing: border-box;
    }

    ::v-deep .el-radio-button {
        width: 76px;
        height: 28px;
        border: 0px;
        margin-right: 12px;
    }

    /* ::v-deep .el-popper {
  width: 76px;
} */

    ::v-deep .el-dropdown-menu--small .el-dropdown-menu__item {
        padding: 0px !important;
        width: 76px;
    }

    ::v-deep .el-dropdown-menu__item {
        display: block !important;
        text-align: center !important;
    }

    .el-dro-item {
        display: block !important;
        text-align: center !important;
        height: 32px !important;
    }

    .sx-box {
        width: 376px;
        border-radius: 4px;
        border: 1px solid rgba(243, 243, 243, 1);
        padding: 24px 20px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        position: absolute;
        right: 16px;
        top: 89px;
        text-align: left;
    }

    .sx-name {
        color: rgba(0, 0, 0, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 7px;
    }

    .sx-btn-box {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }

    .sx-btn {
        width: 112px;
        height: 44px;
        border-radius: 2px;
        background: rgba(243, 244, 246, 1);
        line-height: 44px;
        text-align: center;
        color: var(--, rgba(132, 132, 132, 1));
        font-size: 13px;
    }

    .sx-btns {
        width: 204px;
        height: 44px;
        border-radius: 2px;
        background: rgba(86, 105, 236, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 13px;
        line-height: 44px;
        text-align: center;
    }

    .drawer__title {
        text-align: left;
        color: rgba(27, 27, 27, 1);
        font-family: "Noto Sans SC";
        font-weight: 550;
        font-size: 14px;
        line-height: 14px;
    }

    /* 自定义图标和输入框的对齐 */
    .custom-icon {
        font-size: 14px;
        display: flex;
        align-items: center;
        /* 垂直居中对齐 */
        height: 100%;
        /* 占满父元素高度 */
        padding-left: 10px;
    }

    /* 调整 el-select 的高度以确保图标和文字对齐 */
    .el-select .el-input__inner {
        display: flex;
        align-items: center;
    }

    ::v-deep .el-drawer__body {
        flex: 1 1 0%;
        overflow: hidden;
        padding: 0px;
    }
</style>